import baseAxios from '@/api/base';

baseAxios.defaults.baseURL = `${process.env.VUE_APP_APIPATH}`;

/** FeedBacks api */
/**
 * 取得意見回饋
 * @param {object} data {
  "pageIndex": 0,
  "pageSize": 0,
  "data": {}
  }
 */
export const GetMemberFeedBacks = (data) => baseAxios.post('/FrontEnd/FeedBacks/GetMemberFeedBacks', data);

/**
 * 取得詳細
 */
export const GetMemberFeedBackById = (id) => baseAxios.post('/FrontEnd/FeedBacks/GetMemberFeedBackById', { id });

/**
 * 新增意見回饋
 * @param {object} data {
  "Id": 0
  }
 */
export const AddMemberFeedBack = (data) => baseAxios.post('/FrontEnd/FeedBacks/AddMemberFeedBack', data, {
  headers: {
    'Content-Type': 'multipart/form-data',
  },
});

/**
 * 回覆回饋
 */
export const ReplyMemberFeedBack = (data) => baseAxios.post('/FrontEnd/FeedBacks/ReplyMemberFeedBack', data, {
  headers: {
    'Content-Type': 'multipart/form-data',
  },
});
