<template>
  <!-- 主要內容 -->
  <section class="member pb-8">
    <div class="container">
      <div class="row">
        <div class="col-12 col-md-6 col-lg-8 mb-3">
          <button
            class="btn btn-primary"
            data-toggle="modal"
            data-target="#memberMsg"
            @click="GetCaptchaCode()"
          >
            我要提問
          </button>
        </div>
      </div>
      <div class="row">
        <div class="col-12 pb-6 pb-md-8">
          <ul class="cs-table" data-style="12">
            <li class="table__head d-none d-md-flex">
              <div class="table__head-td">問題</div>
              <div class="table__head-td">時間</div>
              <div class="table__head-td">操作</div>
            </li>
            <template v-for="item in PageInfo.data">
              <li class="table__item" :key="item.id">
                <div class="table__item-td item_Title">
                  <i
                    class="fa fa-check text-success"
                    aria-hidden="true"
                    v-if="item.status === 1"
                  ></i>
                  <i class="fa fa-question" aria-hidden="true" v-else></i>
                  {{ item.feedBackTitle }}
                </div>
                <div class="table__item-td item_createrDate">
                  {{ item.feedBackDate | toTaipei | moment('YYYY-MM-DD HH:mm:ss') }}
                </div>
                <div class="table__item-td item_action">
                  <router-link :to="`/member/feedBacks/${item.id}`">
                    查看
                  </router-link>
                </div>
              </li>
            </template>
          </ul>
        </div>
      </div>
      <!-- 分頁 -->
      <Pagination
        @GetList="GetList()"
        :page.sync="Pagination.pageIndex"
        :page-row="Pagination.pageSize"
        :total-count="PageInfo.count"
      />
    </div>
    <!-- Modal -->
    <div
      class="modal fade"
      id="memberMsg"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5>我要提問</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body ">
            <!-- 彈窗內容 -->
            <el-form
              style="width: 100%;"
              :model="FeedBackForm"
              label-position="top"
              ref="FeedBackForm"
              :rules="FeedBackRules"
              class="cs-form "
              data-style="4"
            >
              <div class="row pl-2 pr-2 mb-2">
                <div class="col-12 col-md-2 col-lg-2 ">
                  <label class="title necessary">標題</label>
                </div>
                <div class="col-12 col-md-10 col-lg-10 " style="padding-left: 0px;">
                  <el-form-item prop="feedBackTitle" class="col-12 col-md-9 mb-2">
                    <el-input
                      :class="`el-input__input`"
                      placeholder="標題"
                      type="text"
                      id="feedBackTitle"
                      v-model="FeedBackForm.feedBackTitle"
                    />
                    <div class="text--wordLimt">{{ FeedBackForm.feedBackTitle.length }}/20</div>
                  </el-form-item>
                </div>
              </div>
              <div class="row pl-2 pr-2 mb-2">
                <div class="col-12 col-md-2 col-lg-2 ">
                  <label class="title necessary">內容</label>
                </div>
                <div class="col-12 col-md-10 col-lg-10 ">
                  <el-form-item prop="feedBackContent">
                    <textarea
                      v-model="FeedBackForm.feedBackContent"
                      class="form-control"
                      style="height:250px;"
                    ></textarea>
                    <div class="text--wordLimt">{{ FeedBackForm.feedBackContent.length }}/150</div>
                  </el-form-item>
                </div>
              </div>
              <div class="row pl-2 pr-2 mb-2">
                <div class="col-12 col-md-2 col-lg-2 ">
                  <label class="title ">檔案上傳</label>
                </div>
                <div class="col-12 col-md-10 col-lg-10 ">
                  <input type="file" ref="file" id="file-uploader" @change="CheckFile" />
                  <div slot="tip" class="el-upload__tip">
                    只能上傳jpg/png文件，且不超過2MB
                  </div>
                </div>
              </div>
              <div class="row pl-2 pr-2 mb-2">
                <div class="col-12 col-md-2 col-lg-2 ">
                  <label class="title necessary">驗證碼</label>
                </div>
                <el-form-item prop="captchaCode" class="col-12 col-md-5">
                  <el-input
                    :class="`el-input__input`"
                    placeholder="驗證碼"
                    type="text"
                    id="captchaCode"
                    v-model="FeedBackForm.captchaCode"
                  />
                </el-form-item>
                <div class="col-12 col-md-4">
                  <img
                    class="img-verify"
                    :src="`data:image/png;base64,${CodeImg}`"
                    @click="GetCaptchaCode"
                    alt="驗證碼"
                  />
                </div>
              </div>
              <div class="footer">
                <div></div>
                <div class="text-right">
                  <button
                    type="button"
                    class="btn--gray px-3 py-1 py-md-1"
                    @click="AddMemberFeedBack"
                  >
                    送出
                  </button>
                </div>
              </div>
            </el-form>
            <!-- 彈窗內容 end -->
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Pagination from '@/components/Pagination.vue';
import { GetMemberFeedBacks, AddMemberFeedBack } from '@/api/FeedBackApi';
import { GetCaptchaCode, GetCaptchaBase64 } from '@/api/CaptchaApi';
/* global $ */

export default {
  components: {
    Pagination,
  },
  data() {
    // 建立你的初始化 model data
    return {
      Pagination: {
        // 有分頁要有此model data
        pageIndex: 1, // 預設從第幾頁開始
        pageSize: 6, // 預設每頁幾筆
        data: {},
      },
      PageInfo: {
        count: 0, // api給的總筆數
        data: [],
      },
      FeedBackForm: {
        feedBackTitle: '',
        feedBackContent: '',
        iploadType: 11,
        code: '',
        captchaCode: '',
      },
      FeedBackRules: {
        feedBackTitle: [
          { required: true, message: '請輸入標題', trigger: 'blur' },
          { max: 20, message: '標題不能超過20個字' },
        ],
        feedBackContent: [
          { required: true, message: '請輸入內容', trigger: 'blur' },
          { max: 150, message: '內容不能超過150個字' },
        ],
        captchaCode: [{ required: true, message: '請輸入驗證碼', trigger: 'blur' }],
      },
      CodeImg: '',
    };
  },
  // 當有設定keep的時候，返回到此頁面會執行這個，不會執行created()，所以這邊就不用放created()
  async activated() {
    if (!this.$route.meta.isBack) {
      // 不是從指定頁面返回的話，進行初始化設定
      this.Pagination = {
        pageIndex: 1, // 預設從第幾頁開始
        pageSize: 6, // 預設每頁幾筆
        data: {},
      };
      this.PageInfo = {
        count: 0, // api給的總筆數
        data: [],
      };
    }
    this.GetList();
  },
  mounted() {},
  methods: {
    GetList() {
      GetMemberFeedBacks(this.Pagination).then((response) => {
        if (response.data.statusCode === 0) {
          this.PageInfo.count = response.data.count;
          this.PageInfo.data = response.data.data;
          if (this.Pagination.pageIndex !== 1 && this.PageInfo.data.length === 0) {
            this.Pagination.pageIndex = 1;
            this.GetList();
          }
        }
      });
    },
    GetCaptchaCode() {
      // 取得驗證碼
      GetCaptchaCode().then((response) => {
        if (response.data.statusCode === 0) {
          this.FeedBackForm.code = response.data.data.code;
          GetCaptchaBase64(this.FeedBackForm.code).then((res) => {
            this.CodeImg = res.data;
          });
        }
      });
    },
    CheckFile() {
      const file = this.$refs.file.files[0];
      const isJPG = file.type === 'image/jpeg';
      const isPNG = file.type === 'image/png';
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isJPG && !isPNG) {
        this.$message.error('上傳圖片只能是 JPG/PNG 格式!');
        this.$refs.file.value = '';
      }
      if (!isLt2M) {
        this.$message.error('上傳圖片大小不能超過 2MB!');
        this.$refs.file.value = '';
      }
    },
    AddMemberFeedBack() {
      this.$refs.FeedBackForm.validate((valid) => {
        if (valid) {
          const uploadedFile = this.$refs.file.files[0];

          const formData = new FormData();
          const objKey = Object.keys(this.FeedBackForm);
          objKey.forEach((item) => {
            formData.append(item, this.FeedBackForm[item]);
          });
          if (uploadedFile) formData.append('files', uploadedFile);
          AddMemberFeedBack(formData).then((response) => {
            if (response.data.statusCode === 0) {
              this.$message({
                showClose: true,
                type: 'success',
                message: '提問成功',
              });
              this.FeedBackForm = {
                feedBackTitle: '',
                feedBackContent: '',
                iploadType: 11,
                code: '',
                captchaCode: '',
              };
              $('#memberMsg').modal('hide');
              this.$refs.file.value = '';
              setTimeout(() => {
                this.GetList();
              }, 1000);
            } else {
              this.GetCaptchaCode();
            }
          });
        } else {
          this.$message({
            showClose: true,
            type: 'error',
            message: '請將資料填寫完整',
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.text--wordLimt {
  font-size: 12px;
  line-height: 0;
  position: absolute;
  bottom: 11px;
  right: 3px;
}
</style>
